//
// List groups
// --------------------------------------------------


// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  padding-right: 0; // reset padding because ul and ol
  padding-left: auto;
}


// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {

  // Align badges within list items
  > .badge {
    float: left;
  }
  > .badge + .badge {
    margin-ight: 5px;
    margin-left: auto;
  }
}
