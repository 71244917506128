//
// RTL Badges
// --------------------------------------------------


// Base class
.badge {

  .nav-pills > li > a > & {
    margin-left: 0px;
    margin-right: 3px;
  }
}
